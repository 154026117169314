import React, {useEffect, useState} from "react"

import {unwrapResult} from '@reduxjs/toolkit'
import {withRouter, useParams} from "react-router-dom";
import {Formik} from "formik";
import * as Yup from "yup";

import {Row, Col, ButtonGroup, Button, Card} from "react-bootstrap"
import Field from "../../components/Field";

import {useSelector, useDispatch} from "react-redux";
import {changePassword} from "./ducks/authSlice";
import {DEV_MODE} from "../../constants";


const initialValues = {
    password: "",
    password_confirm: "",
};

const validationSchema = Yup.object().shape({
    password: Yup.string().required("New password is required"),
    password_confirm: Yup.string().required("Confirm password is required"),
});

const ChangePasswordForm = (props) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(true);

    const me = useSelector(state => state.auth.me);

    const handleSubmit = async (values, {setErrors}) => {
        const resultAction = await dispatch(changePassword({...values}));
        if (changePassword.fulfilled.match(resultAction)) {
            props.history.goBack();
        } else {
            if (resultAction.payload) {
                setErrors(resultAction.payload.field_errors)
            }
        }
    };

    return (
        <>{show && <Formik
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={{offset: 4, span: 4}} className="card">
                            <Card.Body>
                                <Field error={errors.password} label="New Password" htmlFor="password" type="password"/>
                                <Field error={errors.password_confirm} label="Confirm New Password"
                                       htmlFor="password_confirm"
                                       type="password"
                                />
                                <ButtonGroup>
                                    <Button type={"submit"}>
                                        <i className="fa fa-save"/> Change
                                    </Button>
                                    <Button variant={"danger"} onClick={() => props.history.goBack()}>
                                        <i className="fa fa-remove"/> Abort
                                    </Button>
                                </ButtonGroup>
                            </Card.Body>
                        </Col>
                    </Row>
                    {false &&
                    <pre className="m-t-md" style={{
                        fontSize: '1rem',
                        padding: '.25rem .5rem',
                        overflowX: 'scroll',
                    }}
                    >
                            VALUES: {JSON.stringify(values, null, 2)}<br/>
                            ERRORS: {JSON.stringify(errors, null, 2)}
                        </pre>
                    }
                </form>
            )}
        </Formik>}
        </>
    )
}

export default withRouter(ChangePasswordForm);