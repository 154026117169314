/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */


import {Card, Col, Container, Row} from "react-bootstrap";
import React from "react";
import {Formik, FieldArray, Form} from "formik";
import {subMonths, startOfMonth, getMonth, getYear, addDays} from "date-fns";

import * as Yup from "yup";
import ReportTable from "./ReportTable";
import {DEV_MODE} from "../../constants";

const createInitialValues = (lastMonthDate) => ({
    year: getYear(lastMonthDate),
    month: getMonth(lastMonthDate),
})

const validationSchema = Yup.object().shape({
    year: Yup.number().required(),
    month: Yup.number().required()
})

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];


function Reports() {

    const date = subMonths(addDays(startOfMonth(new Date()), 5), 1);
    return (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={createInitialValues(date)}
            //onSubmit={(values, formikHelpers) => onSubmit({values, ...formikHelpers})}
            //onSubmit={(values, formikHelpers) => handleSubmit({values, ...formikHelpers})}
            validationSchema={validationSchema}
        >
            {({
                  values,
                  errors,
                  //touched,
                  //handleChange,
                  //handleBlur,
                  //handleSubmit,
                  submitForm,
                  setFieldValue,
                  //isSubmitting,
                  /* and other goodies */
              }) => (
                <Container fluid>
                    <Row className="mb-2">
                        <Col className={"card"}>
                            <Card.Body>
                                <Form>
                                    <div className="form-group row">
                                        <div className="col-md-2">
                                            <select
                                                className="form-control"
                                                onChange={(e) => setFieldValue("year", parseInt(e.target.value))}
                                                value={values.year}
                                            >

                                                {(new Array(2).fill()).map((_, i) => <option key={i}
                                                                                             value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>)}
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <select
                                                className="form-control"
                                                onChange={(e) => setFieldValue("month", parseInt(e.target.value))}
                                                //defaultValue={new Date().getMonth()}
                                                value={values.month}
                                            >

                                                {(new Array(12).fill()).map((_, i) => <option key={i}
                                                                                              value={i}>{monthNames[i]}</option>)}
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <a className="btn btn-default" href={`/api/v1/reports/courses?year=${values.year}&month=${values.month+1}&format=pdf`}><i className="fa fa-download"></i> Download</a>
                                        </div>

                                    </div>
                                    <div>
                                        {DEV_MODE && false && (
                                            <pre
                                                style={{
                                                    fontSize: "1rem",
                                                    padding: ".25rem .5rem",
                                                    overflowX: "scroll",
                                                }}
                                            >
                  VALUES: {JSON.stringify(values, null, 2)}
                                                <br/>
                  ERRORS: {JSON.stringify(errors, null, 2)}
                </pre>
                                        )}
                                    </div>
                                </Form>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"card"}>
                            <Card.Body>
                                <ReportTable year={values.year} month={values.month + 1}/>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>)}
        </Formik>
    )
}

export default Reports;