/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import {useEffect} from 'react';

export const addBodyClass = className => document.body.classList.add(className);
export const removeBodyClass = className => document.body.classList.remove(className);

// https://stackoverflow.com/a/55537134
export const useBodyClass = (className) => {

    useEffect(

        () => {
            // Set up
            className instanceof Array ? className.map(addBodyClass) : addBodyClass(className);

            // Clean up
            return () => {
                className instanceof Array
                    ? className.map(removeBodyClass)
                    : removeBodyClass(className);
            };
        },
        [className]
    );
}
