import {combineReducers} from 'redux'
import {default as courseReducer} from "./features/courses/ducks/courseSlice"
import {default as participantsReducer} from "./features/courses/ducks/participantsSlice"
import {default as attendancesReducer} from "./features/courses/ducks/attendanceSlice"
import {default as reportsReducer} from "./features/reports/ducks/slice"
import {default as authReducer} from "./features/users/ducks/authSlice"
// import {default as navReducer} from "./features/navigation/ducks/slice";

const combinedReducers = combineReducers({
    //nav: navReducer,
    courses: courseReducer,
    participants: participantsReducer,
    attendances: attendancesReducer,
    reports: reportsReducer,
    auth: authReducer,
    //feedback: feedbackReducer,
})

export default function(state, action){
  // Doesnt work we have to reset initial values of reducers
  //if (action.type === 'trainer/logout/fulfilled') { // check for action type
  //  debugger
  //  state.courses.ids = [];
  //  state.courses.entities = {};
  //}
  return combinedReducers(state, action)
}