/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import * as React from "react";
import {useHistory, useParams} from "react-router-dom";

import {toast} from 'react-toastify';
import {Card} from "react-bootstrap";

import * as Yup from "yup";


import {Formik, FieldArray, Form, Field as FormikField} from "formik";
import {subMonths, startOfMonth, parseISO} from "date-fns";


import Datepicker from "../../components/Datepicker";

import CourseHeader from "./CourseHeader";
import {Col, Container, Row} from "react-bootstrap";
import {DEV_MODE} from "../../constants";
import CourseAttendanceList from "./CourseAttendanceList";
import {useDispatch, useSelector} from "react-redux";
import {fetchCourse, selectCourseById} from "./ducks/courseSlice";
import {fetchParticipants, selectParticipantsByCourseId} from "./ducks/participantsSlice";
import {
    changeAttendance, deleteAttendance,
    fetchAttendances,
    selectAttendancesByCourseId,
    selectAttendancesById
} from "./ducks/attendanceSlice";
import Spinner from "../../components/Spinner";

const createInitialValues = (entry, course, participants) => {
    const itemByParticipant = entry.items.reduce((map, obj) => {
        map[obj.participant_id] = obj;
        return map;
    }, {});
    return {
        date: parseISO(entry.date.iso),
        numberOfUnits: entry.number_of_units,
        minutesPerUnit: entry.minutes_per_unit,
        travelExpenses: entry.travel_expenses,
        participants: participants.map((p) => ({
            id: p.id,
            firstName: p.first_name,
            lastName: p.last_name,
            name: `${p.first_name} ${p.last_name}`,

            // It is possible that itemByParticipant[p.id] is undefined
            // if a participant was added after attendance entry was made
            attendant: itemByParticipant[p.id]?.status === "PRESENT" ? true : false
        })),
        error: null,
    }
};

const validationSchema = Yup.object().shape({
    date: Yup.date().required(),
    numberOfUnits: Yup.number().min(1).max(8).required(),
    minutesPerUnit: Yup.number().min(1).required(),
    travelExpenses: Yup.boolean(),
    participants: Yup.array().of(
        Yup.object().shape({
            id: Yup.number().required(),
            firstName: Yup.string(),
            lastName: Yup.string(),
            name: Yup.string(),
            attendant: Yup.boolean(),
        })
    )
        .required('Must have an participant') // these constraints are shown if and only if inner constraints are satisfied
        .min(1, 'Minimum of 1 participant'),
});


function CourseAttendanceEdit() {
    const history = useHistory();
    const {courseId, attendanceEntryId} = useParams();

    const dispatch = useDispatch();
    const course = useSelector(selectCourseById({id: courseId}));
    const participants = useSelector(selectParticipantsByCourseId({id: courseId}));
    const attendance = useSelector(selectAttendancesById({id: attendanceEntryId}));
    const attendances = useSelector(selectAttendancesByCourseId({id: courseId, isLocked: false}));

    React.useEffect(() => {
        dispatch(fetchCourse({id: courseId}));
        dispatch(fetchParticipants({courseId}));
        dispatch(fetchAttendances({courseId}));
    }, [courseId])

    const handleDelete = async () =>{
        const resultAction = await dispatch(deleteAttendance({id: attendanceEntryId}))
            if(deleteAttendance.fulfilled.match(resultAction)) {
                toast.success(`Attendance entry for course ${course.id} deleted`);
                history.push(`/courses/${courseId}/attendances/add`);
            }
        else {
            if (resultAction.payload) {
                //setErrors(resultAction.payload.field_errors)
                //console.log(resultAction.payload.field_errors)
                toast.error(resultAction.payload.message)
            }
        }
    }

        const handleSubmit = async (values, { setFieldValue, setErrors, resetForm}) => {
            const resultAction = await dispatch(changeAttendance({id: attendanceEntryId, values: values}))
            if(changeAttendance.fulfilled.match(resultAction)) {
                toast.success(`Course session changed`);
                const currentDate = values.date;
                //resetForm();
                //setFieldValue("date", currentDate);
                dispatch(fetchCourse({id: courseId}))
            }
        else {
            if (resultAction.payload) {
                //setErrors(resultAction.payload.field_errors)
                //console.log(resultAction.payload.field_errors)
                toast.error(resultAction.payload.message)
                setErrors(resultAction.payload.message)
            }
        }
    }

    const to = "rechnungen@pet-sprachen.de";
    const subject = !!course ? `Course ${course.id}` : "";
    const body = !!course ? `Cousre No: ${course.id}%0D%0ACourse Name: ${course.name}%0D%0ACompany: ${course.client.name}%0D%0AProgress: ${course.budget_used_in_percent}%` : "";



    if(!course) return <Spinner/>
    //const lessThanOneRemainingUE = course.budget_remaining_in_minutes < course.minutes_per_unit;
    if(!attendance) return <Spinner/>
    if(!Array.isArray(participants)) return <Spinner/>
    if(!Array.isArray(attendances)) return <Spinner/>
    return (
        <Container fluid key={courseId}>
            <Row className="mb-2">
                <Col className="card">
                    <Card.Body>
                        <CourseHeader course={course} isLoading={!course}/>
                    </Card.Body>
                </Col>
            </Row>
            <Row>
                <Col md={10} className="card">
                    <Card.Body>
                        {/*!courseIsLoading && course.budget_exausted && <div className="text-danger">
                            The budget of this course/module is exhausted, therefore no further sessions can be
                            added.<br/>
                            The minimum budget must be at least 1 UE of {course.minutes_per_unit} minutes.<br/><br/>
                            If you think this is wrong, please contact PET Sprachen GmbH<br/><a href="tel:+498920062090"
                                                                                                className="text-danger">+49
                            (089) 200 620 90</a>

                            <div className="m-t-sm">
                                <a
                                    href={`mailto:${to}?subject=${subject}&body=${body}`}
                                    className="btn btn-warning"
                                    data-toogle="tooltip"
                                    title="Send email to PET"
                                >
                                    <i className="fa fa-envelope"/> Mail
                                </a>
                            </div>
                        </div>*/}
                        <Formik
                            key={attendanceEntryId}
                            validateOnChange={false}
                            validateOnBlur={false}
                            initialValues={createInitialValues(attendance, course, participants)}
                            onSubmit={handleSubmit}
                            validationSchema={validationSchema}
                        >
                            {({
                                  values,
                                  errors,
                                  //touched,
                                  //handleChange,
                                  //handleBlur,
                                  //handleSubmit,
                                  submitForm,
                                  setFieldValue,
                                  //isSubmitting,
                                  /* and other goodies */
                              }) => (
                                <Form>
                                    <table className="table table-hover bg-white">
                                        <tbody>
                                        <tr className="bg-light">
                                            <td scope="row" style={{width: "12rem"}}>Date</td>
                                            <td className="">
                                                <Datepicker
                                                    htmlFor="date"
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={values.date}
                                                    onChange={(date) =>
                                                        setFieldValue("date", date)
                                                    }
                                                    minDate={startOfMonth(subMonths(new Date(), 3))}
                                                    maxDate={new Date()}
                                                    showWeekNumbers
                                                    locale="de-DE"
                                                    //showPreviousMonths
                                                    //monthsShown={2}
                                                />
                                            </td>
                                        </tr>
                                        <tr className="bg-light">
                                            <td>Sessions <i>(UE)</i></td>
                                            <td className="">
                                                {/*<Field
                                                    type="number"
                                                    as="input"
                                                    htmlFor="unit"
                                                    className="form-control"
                                                    style={{display: "inline-block"}}
                                                />*/}
                                                <select
                                                    className="form-control"
                                                    onChange={(e) => setFieldValue("numberOfUnits", parseInt(e.target.value))}
                                                    //defaultValue={course.units_per_session}
                                                    value={values.numberOfUnits}
                                                >

                                                    {(new Array(8).fill()).map((_, i) => <option key={i}
                                                                                                 value={i + 1}>{i + 1} UE {i + 1 === course.units_per_session && "(Course default)"}</option>)}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr className="bg-light">
                                            <td>Minutes per Session</td>
                                            <td>
                                                <select className="form-control"
                                                        onChange={(e) => setFieldValue("minutesPerUnit", parseInt(e.target.value))}
                                                        value={values.minutesPerUnit}>
                                                    {Array.from(new Set([attendance.minutes_per_unit, course.minutes_per_unit, 30 , 45, 60])).map(v =>
                                                        <option key={v} value={v}>{v} Min {course.minutes_per_unit === v && "(Course default)"}</option>
                                                    )}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr className="bg-light">
                                            <td>Travel</td>
                                            <td>
                                                <FormikField
                                                    type="checkbox"
                                                    htmlFor="travelExpenses"
                                                    name="travelExpenses"
                                                    disabled={!course.has_travel_expenses}
                                                    hidden={!course.has_travel_expenses}
                                                    className={"align-middle"}
                                                />
                                                {!course.has_travel_expenses && <span>This course has no travel expenses option</span>}
                                            </td>
                                        </tr>
                                        <FieldArray
                                            name="participants"
                                            render={(arrayHelpers) => (
                                                <>{values.participants.map((p, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {p.name}
                                                        </td>
                                                        <td>
                                                            <FormikField
                                                                type="checkbox"
                                                                checked={values.participants[index].attendant}
                                                                onClick={() => setFieldValue(`participants.${index}.attendant`, !values.participants[index].attendant)}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}</>
                                            )}/>
                                        </tbody>
                                        <tfoot className="bg-light">
                                        {values.participants.length > 1 && <tr>
                                            <th>All participants present</th>
                                            <td>
                                                <FormikField
                                                    type="checkbox"
                                                    checked={values.participants.every(p => p.attendant)}
                                                    onClick={() =>
                                                        values.participants.map((p, i) =>
                                                            setFieldValue(`participants.${i}.attendant`,
                                                                values.participants.every(p => p.attendant) ? false : true))
                                                    }
                                                />
                                            </td>
                                        </tr>}
                                        </tfoot>
                                    </table>
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-primary pull-right"
                                                onClick={() => window.confirm("Kurstag editieren?") && submitForm()}><i
                                            className="fa fa-save"/> Edit
                                        </button>
                                        <button type="button" className="btn btn-danger"
                                                onClick={() => window.confirm("Kurstag löschen?") && handleDelete()}><i
                                            className="fa fa-trash"/> Delete
                                        </button>
                                        <a
                                            href={`mailto:${to}?subject=${subject}&body=${body}`}
                                            className="btn btn-warning"
                                            data-toogle="tooltip"
                                            title="Ask PET for support"
                                        >
                                            <i className="fa fa-question-circle"/> Help
                                        </a>
                                    </div>
                                    <div>
                                        {DEV_MODE  && (
                                            <pre
                                                style={{
                                                    fontSize: "1rem",
                                                    padding: ".25rem .5rem",
                                                    overflowX: "scroll",
                                                }}
                                            >
                  VALUES: {JSON.stringify(values, null, 2)}
                                                <br/>
                  ERRORS: {JSON.stringify(errors, null, 2)}
                  <br/>
                  ATTENDANCE: {JSON.stringify(attendance, null, 2)}
                </pre>
                                        )}
                                    </div>
                                </Form>

                            )}
                        </Formik>
                    </Card.Body>
                </Col>
                 <Col className="card ml-2">
                    <Card.Body>
                        <CourseAttendanceList course={course} attendances={attendances}/>
                    </Card.Body>
                </Col>
            </Row>
        </Container>

    )
}

export default CourseAttendanceEdit;