/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import * as React from 'react';
import PropTypes from 'prop-types';

const Content = (props) => {
  return (
      <div className="wrapper wrapper-content">
          {props.children}
      </div>
  );
};

Content.propTypes = {
    children: PropTypes.node
}

export default Content;

