import * as React from "react"
import PropTypes from "prop-types"


function Page({children}){
    React.useEffect(()=>{

    }, [])

    return(
        <div id="page-wrapper" className="gray-bg">
            {children}
        </div>
    )
}

Page.propTypes = {
    children: PropTypes.node
}

export default Page