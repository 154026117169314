import * as React from "react";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Content from "./layout/Content";
import Link from "./layout/Link";
import Page from "./layout/Page";
import Sidebar from "./layout/Sidebar";
import {useBodyClass} from "./theme/utils";
import CourseAttendanceAdd from "./features/courses/CourseAttendanceAdd";
import Courses from "./features/courses/Courses";
import Logout from "./features/users/Logout";
import Reports from "./features/reports/Reports";
import MeForm from "./features/users/MeForm";
import ChangePasswordForm from "./features/users/ChangePasswordForm";
import CourseAttendanceEdit from "./features/courses/CourseAttendanceEdit";



function AppAuth() {
    const isMobile = window.innerWidth <= 768;

    useBodyClass([
        "cdt",
        isMobile ? "body-small" : "__none__",
    ]);

    return (

            <Router>
                <Sidebar>
                    <Link to="/courses">
                        <i className="fa fa-calendar"></i>
                        <span className="nav-label"> Courses</span>
                    </Link>
                    <Link to="/reports">
                        <i className="fa fa-file"></i>
                        <span className="nav-label"> Reports</span>
                    </Link>
                    <Link to="/me">
                        <i className="fa fa-user"></i>
                        <span className="nav-label"> Profile</span>
                    </Link>
                    <Link to="/logout">
                        <i className="fa fa-sign-out"></i>
                        <span className="nav-label"> Logout</span>
                    </Link>
                </Sidebar>
                <Page>
                    <ToastContainer
                        autoClose={8000}
                        pauseOnFocusLoss={true}
                        draggable={true}
                        style={{
                            width: "420px"
                        }}
                    />
                    <Content>
                        <Switch>
                            <Route exact path="/courses/:courseId/attendances/add">
                                <CourseAttendanceAdd/>
                            </Route>
                            <Route exact path="/courses/:courseId/attendances/:attendanceEntryId">
                                <CourseAttendanceEdit/>
                            </Route>
                            <Route exact path="/courses">
                                <Courses/>
                            </Route>
                            <Route exact path="/reports">
                                <Reports/>
                            </Route>
                            <Route exact path="/me">
                                <MeForm/>
                            </Route>
                            <Route exact path="/me/change_password">
                                <ChangePasswordForm/>
                            </Route>
                            <Route exact path="/logout">
                                <Logout/>
                            </Route>
                            <Route exact path="/">
                                <Redirect to="/courses"/>
                            </Route>
                        </Switch>
                    </Content>

                </Page>
            </Router>
    );
}

export default AppAuth;
