import * as React from "react"
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchReports} from "./ducks/slice";
import Spinner from "../../components/Spinner";


function ReportTable({year = 2020, month = 12}) {
    //const {isLoading, isFetching, data: body, refetch} = useReport(year, month);
        const dispatch = useDispatch();

        const reports = useSelector((state) => state.reports.ids.map(id => state.reports.entities[id]).filter(r => r.year===year && r.month===month));
        const isPending = useSelector(state => state.reports.loading === "pending") && reports.length === 0;

    React.useEffect(() => {
        dispatch(fetchReports({year, month}))
    }, [year, month])

    if (isPending) return <Spinner/>
    return (
        <>

            {reports.length === 0 && <h5 className="text-center">No data available</h5>}
            {reports.map(report =>
                <table key={report.id} className="table table-hover table-bordered">
                    <thead>
                    <tr>
                        <th>Course</th>
                        <th>{report.course_id}</th>
                        <th colSpan={2}>{report.name} <small className="pull-right">{report.company}</small></th>
                    </tr>
                    <tr>
                        <th>Date</th>
                        <th>Sessions</th>
                        <th width={1} data-toggle="tooltip" title="Travel Expenses">Travel</th>
                        <th>Participants</th>
                    </tr>
                    </thead>
                    <tbody>
                    {report.sessions.map((s,i) =>
                    <tr key={i}>
                        <td className="text-nowrap" width={1}>{s.is_locked ? s.date.date : <Link to={`/courses/${report.course_id}/attendances/${s.id}`}>{s.date.date}</Link>}</td>
                        <td className="text-nowrap text-right" width={1}>{s.number_of_units}x {s.minutes_per_unit} Min</td>
                        <td className="text-nowrap text-center" width={1}>{s.has_travel_expenses && <i className="fa fa-check"/>}</td>
                        <td>
                            {s.participants.length > 0 ? s.participants.map(p => p.name).join(", ") : <i>Late Cancellation</i>}
                            {/*<small className="pull-right">{s.id}</small>*/}
                        </td>
                    </tr>
                    )}
                    </tbody>
                    <tfoot>
                    <tr className="bg-light">
                       <td className="font-bold">Total</td>
                       <td className="text-nowrap font-bold text-right">
                           {Object.keys(report.sessions_sum_min).map((k,i)=> <React.Fragment key={i}>{report.sessions_sum_min[k].number_of_units}x {k} Min<br/></React.Fragment>)}
                       </td>
                       <td className="text-center font-bold">
                           {Object.keys(report.sessions_sum_min).map(k=> report.sessions_sum_min[k].number_of_travel_expenses > 0 ? `${report.sessions_sum_min[k].number_of_travel_expenses}x` : "" )}
                       </td>
                        <td></td>
                    </tr>
                    </tfoot>
            </table>
            )}

        </>
    )
}

export default ReportTable;