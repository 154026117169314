import React, { useEffect } from "react";
import {
  Redirect,
} from "react-router-dom";

import { useDispatch } from "react-redux";
import { logout } from "./ducks/authSlice";

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);
  return <Redirect to="/" />;
}
