/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */


import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {changeMe} from "./ducks/authSlice";
import {Formik, Form} from "formik";
import React from "react";
import Field from "../../components/Field";
import {DEV_MODE} from "../../constants";
import {toast} from "react-toastify";


const createInitialValues = (trainer) => ({
    firstName: trainer.first_name,
    lastName: trainer.last_name,
    email: trainer.email,
    phone: trainer.phone,
    //dateOfBirth: !!trainer.date_of_birth ? trainer.date_of_birth : ""

    street: trainer.street,
    zip: trainer.zip,
    city: trainer.city,
    country: !!trainer.country ? trainer.country : "",

    bank: !!trainer.bank ? trainer.bank : "",
    iban: !!trainer.iban ? trainer.iban : "",
    bic: !!trainer.bic ? trainer.bic : "",
});

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    street: Yup.string().required(),
    zip: Yup.string().required(),
    city: Yup.string().required(),
    country: Yup.string(),
    bank: Yup.string(),
    iban: Yup.string(),
    bic: Yup.string(),
});

function MeForm(){
    const dispatch = useDispatch();
    const me = useSelector(state => state.auth.me);

    const handleSubmit = async (values, {setErrors}) => {
        const resultAction = await dispatch(changeMe(values));
        if (changeMe.fulfilled.match(resultAction)) {
            toast.success("Saved your profile")
        } else {
            if (resultAction.payload) {
                setErrors(resultAction.payload.field_errors)
            }
        }
    };


    return(
        <Formik
            initialValues={createInitialValues(me)}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
        <Container fluid>
            <Card>
                <Card.Body>
            <Row className="mb-2">
                <Col>
                        <Field
                            label="First name"
                            error={errors.firstName}
                            htmlFor="firstName"
                            placeholder="Your first name"
                        />
                        <Field
                            label="Last name"
                            error={errors.lastName}
                            htmlFor="lastName"
                            placeholder="Your last name"
                        />
                        <Field
                            label="Phone"
                            error={errors.phone}
                            htmlFor="phone"
                            placeholder="Your phone number"
                        />
                        <Field
                            label="Email"
                            error={errors.email}
                            htmlFor="email"
                            placeholder="Your email"
                            disabled={true}
                        />
                </Col>
                <Col>
                        <Field
                            label="Street"
                            error={errors.street}
                            htmlFor="street"
                            placeholder="Your street"
                        />
                        <Field
                            label="Zip Code"
                            error={errors.zip}
                            htmlFor="zip"
                            placeholder="Your zip code"
                        />
                         <Field
                            label="City"
                            error={errors.city}
                            htmlFor="city"
                            placeholder="Your city"
                        />
                        <Field
                            label="Country"
                            error={errors.country}
                            htmlFor="country"
                            placeholder="Your country"
                        />
                </Col>
                <Col>
                        <Field
                            label="Bank"
                            error={errors.bank}
                            htmlFor="bank"
                            placeholder="Your bank name"
                        />
                        <Field
                            label="IBAN"
                            error={errors.iban}
                            htmlFor="iban"
                            placeholder="Your IBAN"
                        />
                         <Field
                            label="BIC"
                            error={errors.bic}
                            htmlFor="bic"
                            placeholder="Your BIC"
                        />
                </Col>
            </Row>
                    <Row>
                        <Col>
                            <Button type={"submit"}>
                                        <i className="fa fa-save"/> Save
                                    </Button>
                        </Col>
                    </Row>
                    </Card.Body>
                </Card>
        </Container>
                    { DEV_MODE && false &&
                    <pre className="m-t-md" style={{
                        fontSize: '1rem',
                        padding: '.25rem .5rem',
                        overflowX: 'scroll',
                    }}
                    >
                            VALUES: {JSON.stringify(values, null, 2)}<br/>
                            ERRORS: {JSON.stringify(errors, null, 2)}
                        </pre>
                    }
                </Form>)}
        </Formik>
    )

}

export default MeForm;
