import * as React from "react"
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchCourses} from "./ducks/courseSlice";


function CoursesTable(){

    const [qNumber, setQNumber] = React.useState("")
    const [qName, setQName] = React.useState("")

    const dispatch = useDispatch();
    let {ids: courseIds, entities: courseEntities} = useSelector((state) => state.courses);
    let courses = courseIds.map(cid => courseEntities[cid]);

    if (qName !== "") {
        courses = courses.filter(c => c.name.toUpperCase().includes(qName.toUpperCase()))
    }

    if (qNumber !== "") {
        courses = courses.filter(c => c.id.toString().includes(qNumber.toUpperCase()))
    }

    React.useEffect(()=>{
        dispatch(fetchCourses({number: qNumber, name: qName}));
    }, [qNumber, qName])

    return(
        <table className="table table-hover bg-white">
            <thead>
            <tr>
                <th></th>
                <th style={{minWidth: 80}}>Number</th>
                <th className="col-5">Course</th>
                <th className="col-1">Participants</th>
                <th className="col-3">Company</th>
                <th className="col-1 text-right">Progress</th>
                <th className="col-1 text-center">Begin</th>
                <th className="col-1 text-center">Last</th>
            </tr>
            <tr>
                <th></th>
                <th>
                    <input
                        className="form-control form-control-sm"
                        placeholder="Nr."
                        onChange={(e) => setQNumber(e.target.value)}
                        value={qNumber}
                    />

                </th>
                <th>
                    <div className={qName !== "" ? "input-group" : undefined}>

                    <input
                        className="form-control form-control-sm"
                        placeholder="Search"
                        onChange={(e) => setQName(e.target.value)}
                        value={qName}/>
                        {qName !== "" && (
                <span className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => setQName("")}
                  >
                    <i className="fa fa-remove"></i>
                  </button>
                </span>
              )}

                    </div>
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {courses.map(c => {
                const to = "rechnungen@pet-sprachen.de";
                const subject = `Course ${c.id}`;
                const body = `Cousre No: ${c.id}%0D%0ACourse Name: ${c.name}%0D%0ACompany: ${c.client.name}%0D%0AProgress: ${c.budget_used_in_percent}%`;
                return (<tr key={c.id} className="">
                <td>
                    <div className="btn-group">
                    <NavLink to={`/courses/${c.id}/attendances/add`}>
                     <button className="btn btn-sm btn-primary" data-toggle="tooltip" title="Add Attendance"><i className="fa fa-plus"/></button>
                    </NavLink>
                        <a
                            href={`mailto:${to}?subject=${subject}&body=${body}`}
                            className="btn btn-sm btn-warning"
                            data-toogle="tooltip"
                            title="Ask PET for support"
                        ><i className="fa fa-question-circle"/></a>
                        </div>
                </td>
                <td>{c.id}</td>
                <td className="text-left text-nowrap" style={{width:1}}>{c.name}</td>
                <td className="text-center">{c.participants.length}</td>
                <td className="text-left text-nowrap" style={{width:1}}>{c.client.name}</td>
                <td className="text-right">{c.budget_used_in_percent}%</td>
                <td className="text-right text-nowrap">{c.begin.date}</td>
                <td className="text-right text-nowrap">{!!c.last_session && c.last_session.date}</td>
            </tr>)
            })}
            </tbody>
        </table>
    )
}

export default CoursesTable;