/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */
import {createSlice, createEntityAdapter, createAsyncThunk, createSelector} from "@reduxjs/toolkit";
import request from "superagent";
import {logout} from "../../users/ducks/authSlice";

const adapter = createEntityAdapter({ sortComparer: (a, b) => b.id - a.id });


export const fetchReports = createAsyncThunk(
  "courseReports/fetchReports",
  async ({year, month}, { rejectWithValue }) => {
    try {
        const response = await request.get(`/api/v1/reports/courses?year=${year}&month=${month}&format=json`).accept("json");
        return response.body.results;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);
const initialState = adapter.getInitialState({
    loading: "idle"
});
export const slice = createSlice({
  name: "reports",
  initialState: initialState,
    extraReducers:{
      [fetchReports.fulfilled]: (state, action) => {
          adapter.upsertMany(state, action.payload);
          state.loading = "idle";
        },
        [fetchReports.pending]:(state, action)=>{
          state.loading = "pending"
        },
        [fetchReports.rejected]: (state, action)=> {
            this.state.loading = "idle"
        },
        [logout.fulfilled]: (state, action) =>{
            adapter.removeAll(state)
        },
    }
});

export const selectors = adapter.getSelectors((state) => state.reports);

//export const selectReports = ({id, year=null, month=null}) =>{
//    return createSelector((state) => state, ({reports}) =>{
//        const result = reports.ids.map(id => attendances.entities[id]).filter(a => a.course_id === course.id);
//        if(isLocked !== null){
//            return result.filter(a => a.is_locked === isLocked)
//        }
//        return result
//    })
//}

const reducer = slice.reducer;
export default reducer;
