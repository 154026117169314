import React from "react";

import {Formik, Form} from "formik";
import {useDispatch} from "react-redux";

import {useHistory} from "react-router-dom";

//import {toast} from 'react-toastify';

import * as Yup from "yup";

import Field from "../../components/Field";
import {login} from "./ducks/authSlice";

import {useBodyClass} from "../../theme/utils";


const initialValues = {
    email: "",
    password: "",
    otp: "",
};

const validationSchema = Yup.object().shape({
    email: Yup.string().required(),
    password: Yup.string().required(),
    otp: Yup.string().optional(),
});


export default function LoginForm() {
    const dispatch = useDispatch();
    const history = useHistory();

    useBodyClass("gray-bg");

    const handleSubmit = async (values, {setErrors}) => {
        const resultAction = await dispatch(login(values));
        if (login.fulfilled.match(resultAction)) {
            //const user = unwrapResult(resultAction);
            //if(user.is_expired){
            //    //history.push(`/users/${user.id}/change_password`);
            //    //toast.success(<span>Your password is expired. Please consider to <Link to={`/users/${user.id}/change_password`}>change it.</Link></span>);
            //}
            history.push(`/`)
        } else {
            if (resultAction.payload) {
                setErrors(resultAction.payload.field_errors)
                //console.log(resultAction.payload.field_errors)
            }
        }
    };

    return (
        <div className="container-fluid">
            <div style={{margin: "0 auto", padding: "100px 10px 10px 10px"}}>
                <div className="row justify-content-center">
                    <div className="col-md-4 col-sm-12 p-sm bg-white">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validateOnMount={false}
                            onSubmit={handleSubmit}
                            render={({errors}) => (
                                <Form>
                                    {/*We use error.otp because we want to not showe the reason for error */}
                                    <Field error={errors.password} htmlFor="email"
                                           placeholder="Your email address"/>
                                    <Field error={errors.password} htmlFor="password"
                                           placeholder="Your password" type="password"/>
                                    <button className="btn btn-primary block full-width m-b" type="submit">
                                        <i className="fa fa-sign-in"/> Login
                                    </button>
                                    <div className="btn-group btn-block">
                                        <a
                                            href="/forgot_password"
                                            className="btn btn-sm btn-white"
                                        >
                                            Reset password
                                        </a>
                                    </div>
                                </Form>


                            )}
                        />
                    </div>
                </div>
                <div className="row justify-content-center m-t-md">
                    <div className="col-md-4 col-sm-12 border-top">
                        <div className="row m-t-sm">
                            <div className="col text-left">
                                <small>ConDevTec GmbH</small>
                            </div>
                            <div className="col text-center">
                                <small>Made with <i className="fa fa-heart" style={{color: "#d40e0e"}}/> in
                                    Munich</small>
                            </div>
                            <div className="col text-right">
                                <small>&copy; 2018 - {new Date().getFullYear()}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
