/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import * as React from "react";
import {Col, Row} from "react-bootstrap";

function CourseHeader({course, isLoading}) {
    //const {courseId} = useParams();
    //const {isLoading, data: course} = useCourse(courseId)

    return (
        <>
            {!isLoading && <>
                <Row>
                    <Col xs={5} sm={3} md={2} lg={2} xl={2}>
                        Number:
                    </Col>
                    <Col>
                        <b>{course.id}</b>
                    </Col>
                    <Col xs={5} sm={3} md={2} lg={2} xl={2}>
                        Participants:
                    </Col>
                    <Col xs={5} sm={3} md={2} lg={2} xl={2}>
                        <b>{course.participants.length}</b>
                    </Col>
                </Row>
                 <Row>
                    <Col xs={5} sm={3} md={2} lg={2} xl={2}>
                        Begin:
                    </Col>
                    <Col>
                        <b>{!!course.begin && course.begin.date}</b>
                    </Col>
                    <Col xs={5} sm={3} md={2} lg={2} xl={2}>
                        Last:
                    </Col>
                    <Col xs={5} sm={3} md={2} lg={2} xl={2}>
                        <b>{!!course.last_session && course.last_session.date}</b>
                    </Col>
                </Row>
                <Row>
                    <Col xs={5} sm={3} md={2} lg={2} xl={2}>
                        Name:
                    </Col>
                    <Col>
                        <b>{course.name}</b>
                    </Col>
                    <Col xs={5} sm={3} md={2} lg={2} xl={2}>
                        Progress:
                    </Col>
                    <Col xs={5} sm={3} md={2} lg={2} xl={2}>
                        <b>{course.budget_used_in_percent}%</b>
                    </Col>
                </Row>
                <Row>
                    <Col xs={5} sm={3} md={2} lg={2} xl={2}>
                        Company
                    </Col>
                    <Col>
                        <b>{course.client.name}</b>
                    </Col>
                    <Col xs={5} sm={3} md={2} lg={2} xl={2}>
                        Total:
                    </Col>
                    <Col xs={5} sm={3} md={2} lg={2} xl={2}>
                        <b>{course.budget_in_units} UE a {course.minutes_per_unit} Min.</b>
                    </Col>
                </Row>
                <Row>
                    <Col xs={5} sm={3} md={2} lg={2} xl={2}>
                    </Col>
                    <Col>
                    </Col>
                    <Col xs={5} sm={3} md={2} lg={2} xl={2}>
                        Remaining:
                    </Col>
                    <Col xs={5} sm={3} md={2} lg={2} xl={2}>
                        <b>{Number.isInteger(course.budget_remaining_in_units) ? course.budget_remaining_in_units : course.budget_remaining_in_units.toFixed(2)} UE a {course.minutes_per_unit} Min.</b>
                    </Col>
                </Row>

            </>}
        </>
    )
}

export default CourseHeader