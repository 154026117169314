/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import * as React from "react";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Spinner from "../../components/Spinner";

function CourseAttendanceList({course, attendances}){
    const {attendanceEntryId} = useParams();
    const isPending = useSelector(state => state.attendances.loading === "pending") && attendances.length === 0;

    return (
        <div>
            <ul className="list-group">
                {!course.budget_exausted && <li className="list-group-item p-0">
                <Link className={`btn ${!attendanceEntryId ? "btn-primary text-white" : "text-dark"}`} style={{width: "100%"}}
                          to={`/courses/${course.id}/attendances/add`}>New Attendance</Link>
            </li>}
            {isPending ? <span className="m-t-md"><Spinner/></span>: attendances.length > 0 ? attendances.map(e =>
                <li className="list-group-item p-0" key={e.date.epoch}>
                    {e.is_locked ? e.date.date : <Link style={{width: "100%"}}
                                                           className={`btn ${e.id === parseInt(attendanceEntryId) ? "btn-primary text-white" : "text-dark"}`}
                                                           to={`/courses/${course.id}/attendances/${e.id}`}>{e.date.date}</Link>}
                </li>) : <span className="m-t-md text-center">No editable attendances</span>}
        </ul>
            </div>
            )
}

export default CourseAttendanceList;