/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */
import {createSlice, createEntityAdapter, createAsyncThunk, createSelector} from "@reduxjs/toolkit";
import request from "superagent";
import {logout} from "../../users/ducks/authSlice";

const adapter = createEntityAdapter({sortComparer: (a, b) => b.id - a.id});
const initialState = adapter.getInitialState();

export const fetchParticipants = createAsyncThunk(
    "courseParticipants/fetchParticipants",
    async ({courseId}, {rejectWithValue}) => {
        try {
            const response = await request.get(`/api/v1/courses/${courseId}/participants`)
                .accept("json")
            return response.body.results;
        } catch (e) {
            if (!e.response) {
                throw e;
            }
            return rejectWithValue(e.response.body);
        }
    }
);

export const slice = createSlice({
    name: "participants",
    initialState: initialState,
    extraReducers: {
        [fetchParticipants.fulfilled]: (state, action) => {
            adapter.upsertMany(state, action.payload);
        },
        [logout.fulfilled]: (state, action) => {
            adapter.removeAll(state)
        },
    }
});

export const selectors = adapter.getSelectors((state) => state.participants);

export const selectParticipantsByCourseId = ({id}) => {
    return createSelector((state) => state, ({courses, participants}) => {
        const course = courses.entities[parseInt(id)];
        if (course === undefined) {
            return null
        }
        if (!course.participants.every(id => participants.ids.includes(id))) {
            return null
        }
        return course.participants.map(id => participants.entities[id]);
    })
}

const reducer = slice.reducer;
export default reducer;
