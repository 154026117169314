/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import * as React from "react";
import CoursesTable from "./CoursesTable";
import {Card, Col, Container, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {fetchCourses} from "./ducks/courseSlice";

function Courses() {

    const dispatch = useDispatch();
    const courses = useSelector((state) => state.courses.ids);

    React.useEffect(() => {
          dispatch(fetchCourses({}));
      }, []);


    return (
        <Container fluid>
            <Row className="mb-2">
                <Col className={"card"}>
                    <Card.Body>
                        {`Your are assigned to ${courses.length} courses`}
                    </Card.Body>
                </Col>
            </Row>
            <Row>
                <Col className={"card"}>
                    <Card.Body>
                        <CoursesTable/>
                    </Card.Body>
                </Col>
            </Row>
        </Container>
    )
}

export default Courses