/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */
import {createSlice, createEntityAdapter, createAsyncThunk, createSelector} from "@reduxjs/toolkit";
import request from "superagent";

import {logout} from "../../users/ducks/authSlice";

const adapter = createEntityAdapter({ sortComparer: (a, b) => b.date.epoch - a.date.epoch });

export const fetchAttendances = createAsyncThunk(
    "courseAttendances/fetchAttendances",
    async ({courseId}, {rejectWithValue}) =>{
        try {
        const response = await request.get(`/api/v1/attendance_entries?course=${courseId}`)
            .accept("json")
        return response.body.results;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
)

export const addAttendance = createAsyncThunk(
  "courseAttendances/addAttendance",
  async ({courseId, values}, { rejectWithValue }) => {
    try {
        const response = await request.post(`/api/v1/courses/${courseId}/attendances`)
                .send(values)
                .accept("json");
        return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const changeAttendance = createAsyncThunk(
  "courseAttendances/changeAttendance",
  async ({id, values}, { rejectWithValue }) => {
    try {
        const response = await request.put(`/api/v1/attendance_entries/${id}`)
                .send(values)
                .accept("json");
        return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const deleteAttendance = createAsyncThunk(
  "courseAttendances/deleteAttendance",
  async ({id}, { rejectWithValue }) => {
    try {
        const response = await request.delete(`/api/v1/attendance_entries/${id}`).accept("json");
        return parseInt(id);
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);


const initialState = adapter.getInitialState({
    loading: "idle"
});
export const slice = createSlice({
    name: "attendances",
    initialState: initialState,
    extraReducers: {
        [addAttendance.fulfilled]: (state, action) => {
            adapter.upsertOne(state, action.payload);
        },
        [fetchAttendances.rejected]: (state, action) => {
          state.loading = "idle";
        },
        [fetchAttendances.pending]: (state, action) => {
            state.loading = "pending";
        },
        [fetchAttendances.fulfilled]: (state, action) => {
            adapter.upsertMany(state, action.payload);
            state.loading = "idle";
        },
        [changeAttendance.fulfilled]: (state, action) =>{
            adapter.upsertOne(state, action.payload);
        },
        [deleteAttendance.fulfilled]: (state, action) =>{
            adapter.removeOne(state, action.payload);
        },
        [logout.fulfilled]: (state, action) =>{
            adapter.removeAll(state)
        },
    }
});

export const selectors = adapter.getSelectors((state) => state.attendances);

export const selectAttendancesByCourseId = ({id, isLocked=null}) =>{
    return createSelector((state) => state, ({courses, attendances}) =>{
        const course = courses.entities[parseInt(id)];
        if(course === undefined){
            return null
        }
        const result = attendances.ids.map(id => attendances.entities[id]).filter(a => a.course_id === course.id);
        if(isLocked !== null){
            return result.filter(a => a.is_locked === isLocked)
        }
        return result
    })
}

export const selectAttendancesById = ({id}) => {
    return createSelector((state) => state, ({attendances}) =>{
        const obj = attendances.entities[parseInt(id)];
        if(obj === undefined){
            return null
        }
        return obj
    })
}

const reducer = slice.reducer;
export default reducer;
