/* eslint-disable jsx-a11y/anchor-is-valid */

import * as React from "react";
import PropTypes from "prop-types"
import {DEV_MODE} from "../constants";
import logo from "../logo.svg";
import {useSelector} from "react-redux";

const SideBar = ({children}) => {

    const me = useSelector(state => state.auth.me);

    return (
        <nav className="navbar-default navbar-static-side" role="navigation">
            <div className="sidebar-collapse">
                <ul className="nav metismenu" id="side-menu">
                    <li className="nav-header" style={{backgroundColor: DEV_MODE ? "darkmagenta": "#2f4050"}}>
                        <div className="dropdown profile-element">
                            <a data-toggle="dropdown" className="dropdown-toggle" href="javascript:void(0)">
                                <span className="block m-t-xs font-bold">{me.first_name} {me.last_name}</span>
                                {false &&
                                <span className="text-muted text-xs block">menu <b className="caret"></b></span>}
                            </a>
                            {false && <ul className="dropdown-menu animated fadeInRight m-t-xs">
                                <li><a className="dropdown-item" href="login.html">Logout</a></li>
                            </ul>}
                        </div>
                        <div className="logo-element">
                            <img width={24} height={24} src={logo} alt="Logo"/>
                        </div>
                    </li>
                    {children}
                </ul>

            </div>
        </nav>

    )
}

SideBar.propTypes = {
    children: PropTypes.node
}

export default SideBar;