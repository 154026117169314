import {createSlice, createAsyncThunk, createSelector} from '@reduxjs/toolkit'
import request from "superagent";


export const changeMe = createAsyncThunk(
  "trainer/changeMe",
  async (values, { rejectWithValue }) => {
    try {
      const response = await request
        .put(`/api/v1/trainers/me`)
        .field("first_name", values.firstName)
        .field("last_name", values.lastName)
        //.field("date_of_birth", values.dateOfBirth)
        .field("phone", values.phone)
        .field("street", values.street)
        .field("zip", values.zip)
        .field("city", values.city)
        .field("country", values.country)
        .field("bank", values.bank)
        .field("iban", values.iban)
        .field("bic", values.bic)
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const changePassword = createAsyncThunk(
  "trainer/changePassword",
  async ({ password, password_confirm }, { rejectWithValue }) => {
    try {
      const response = await request
        .put(`/api/v1/trainers/change_password`)
        .field("password", password)
        .field("password_confirm", password_confirm)
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const login = createAsyncThunk("trainer/login", async ({email, password, otp}, {rejectWithValue}) => {
  try {
    const response = await request.post("/api/v1/trainers/login")
        .field('email', email)
        .field('password', password)
        .field('otp', otp);
    return response.body;
  }catch(e){
    if(!e.response){
      throw e;
    }
    return rejectWithValue(e.response.body);
  }
});

export const forgotPassword = createAsyncThunk(
  "trainer/forgotPassword",
  async ({ email, otp }, { rejectWithValue }) => {
    try {
      const response = await request
        .post("/api/v1/trainers/forgot_password")
        .field("email", email)
        .field("otp", otp);
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "trainer/resetPassword",
  async ({ password, password_confirm, otp, token }, { rejectWithValue }) => {
    try {
      const response = await request
        .post(`/api/v1/trainers/reset_password?t=${token}`)
        .field("password", password)
        .field("password_confirm", password_confirm)
        .field("otp", otp);

      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const logout = createAsyncThunk("trainer/logout", async () => {
  const response = await request.post("/api/v1/trainers/logout");
  return response.body;
});

export const fetchMe = createAsyncThunk("trainer/fetchMe", async () => {
  const response = await request.get("/api/v1/trainers/me").accept('json');
  return response.body;
});

const initialState = {
  me: null,
  pending: true
};

export const slice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    //[login.pending]: (state, action) => {},
    [login.fulfilled]: (state, action) => {
      state.me = action.payload;
    },
    //[logout.pending]: (state, action) => {},
    [logout.fulfilled]: (state) => {
      state.me = null;
    },
    [logout.rejected]: (state) => {
      state.me = null
    },
    [changeMe.fulfilled]: (state, action) =>{
      state.me = action.payload;
      state.pending = false;
    },
    [fetchMe.fulfilled]:(state, action)=>{
      state.me = action.payload;
      state.pending = false;
    },
    [fetchMe.rejected]:(state)=>{
      state.me = null;
      state.pending = false;
    }
  }
});

export const isAuthenticated = createSelector(
  state => state.auth,
  auth => !!auth.me && !auth.pending
)

export const hasRole = (roles) =>
  createSelector(
      state => state.auth,
      auth => auth.me.roles.includes(roles)
  )


const reducer = slice.reducer;
export default reducer;
