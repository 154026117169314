import React from "react";
import PropTypes from "prop-types"

import {Field as _Field, ErrorMessage} from "formik"

function Field(props) {

    // eslint-disable-next-line react/prop-types
    const {htmlFor, error, label, tooltip, placeholder, type, disabled, as, style} = props;

    return (
        <div className={"form-group" + (error ? " has-error " : "")} style={style}>
            {label ? <label htmlFor={htmlFor}><span data-toggle="tooltip" title={tooltip}>{label}</span></label> : ""}
            <_Field
                as={as}
                name={htmlFor}
                className="form-control"
                type={type}
                placeholder={placeholder}
                disabled={disabled}
            />

            <ErrorMessage
                name={htmlFor}
                //render={e => {e instanceof Array ? e.map(msg => <label className="error">{msg}</label>) : <label className="error">{e}</label>}}
                //render={e => e instanceof Array ? <ul>{e.map(m => <li><label key={m} className="error">{m}</label></li>)}</ul> : <label className="error">{e}</label>}
                render={msg => <label className="error">{msg}</label>}
            />
        </div>
    )

}

Field.defaultProps={
    type: "text",
    placeholder: "",
    label: "",
    tooltip: "",
    disabled: false,
    as: "input"
};

Field.props = {
    htmlFor: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
}

export default Field