/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */
import {createSlice, createEntityAdapter, createAsyncThunk, createSelector} from "@reduxjs/toolkit";
import request from "superagent";

import {logout} from "../../users/ducks/authSlice";

const adapter = createEntityAdapter({sortComparer: (a, b) => b.id - a.id});
const initialState = adapter.getInitialState();

export const fetchCourses = createAsyncThunk(
    "courses/fetchCourses",
    async ({number = null, name = null}, {rejectWithValue}) => {
        try {
            let url = `/api/v1/courses?`
            if (!!number && Number.isInteger(parseInt(number))) {
                url = `${url}number=${number}&`
            }
            if (!!name) {
                url = `${url}name=${name}&`
            }
            const response = await request.get(url).accept("json");
            return response.body.results;
        } catch (e) {
            if (!e.response) {
                throw e;
            }
            return rejectWithValue(e.response.body);
        }
    }
);

export const fetchCourse = createAsyncThunk(
    "courses/fetchCourse",
    async ({id}, {rejectWithValue}) => {
        try {
            let url = `/api/v1/courses/${id}`
            const response = await request.get(url).accept("json");
            return response.body;
        } catch (e) {
            if (!e.response) {
                throw e;
            }
            return rejectWithValue(e.response.body);
        }
    }
);

export const slice = createSlice({
    name: "courses",
    initialState: initialState,
    extraReducers: {
        [fetchCourses.fulfilled]: (state, action) => {
            adapter.upsertMany(state, action.payload);
        },
        [fetchCourse.fulfilled]: (state, action) => {
            adapter.upsertOne(state, action.payload);
        },
        [logout.fulfilled]: (state, action) => {
            adapter.removeAll(state)
        },
    }
});

export const selectors = adapter.getSelectors((state) => state.courses);

export const selectCourseById = ({id}) => {
    return createSelector((state) => state, ({courses}) => {
        return courses.entities[parseInt(id)];
    })
}


const reducer = slice.reducer;
export default reducer;
