import * as React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {fetchMe, isAuthenticated} from "./features/users/ducks/authSlice";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";

import AppAuth from "./AppAuth";
import LoginForm from "./features/users/LoginForm";
import ForgotPasswordForm from "./features/users/ForgotPasswordForm";
import ResetPasswordForm from "./features/users/ResetPasswordForm";
import Spinner from "./components/Spinner";

export default function App() {
    const dispatch = useDispatch();
    const pending = useSelector(state => state.auth.pending);
    const isAuth = useSelector(isAuthenticated);

    React.useEffect(() => {
        dispatch(fetchMe());
    }, []);

    if(pending) return <Spinner/>

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/login">
                    <LoginForm/>
                </Route>
                <Route exact path="/forgot_password">
                    <ForgotPasswordForm/>
                </Route>
                <Route exact path="/reset_password">
                    <ResetPasswordForm/>
                </Route>
                {!isAuth ? <Redirect to="/login"/> : <AppAuth/>}
            </Switch>
        </BrowserRouter>
    );
}