import * as React from "react";

import {Formik, Form} from "formik";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import * as Yup from "yup";
import {useLocation} from "react-router-dom";
import queryString from "query-string";

import Field from "../../components/Field";
import {useBodyClass} from "../../theme/utils";
import {resetPassword} from "./ducks/authSlice";

const useQuery = () => {
    return queryString.parse(useLocation().search, {parseNumbers: true});
};

const createInitialValues = (token) => ({
    password: "",
    password_confirm: "",
    otp: "",
    token: token,
});

const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    password_confirm: Yup.string().required("Confirm password is required"),
    otp: Yup.string().optional(),
});

export default function ResetPasswordForm(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [notifications, setNotifications] = React.useState([]);

    const query = useQuery();

    useBodyClass("gray-bg");

    const handleSubmit = async (values, {setErrors, resetForm}) => {
        const resultAction = await dispatch(resetPassword(values));
        if (resetPassword.fulfilled.match(resultAction)) {
            resetForm();
            setNotifications([
                ...notifications,
                "Password change was successful. Please login with your new credentials.",
            ]);
            setTimeout(() => {
                history.push(`/login`);
            }, 10000);
        } else {
            if (resultAction.payload) {
                setErrors(resultAction.payload.field_errors);
                setNotifications([
                ...notifications, ...Object.values(resultAction.payload.field_errors),
            ]);
            }
        }
    };

    return (
        <div className="container-fluid">
            <div style={{margin: "0 auto", padding: "100px 10px 10px 10px"}}>
                {notifications.length > 0 && (
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-12 bg-primary p-xs">
                            {notifications.map((n) => (
                                <span>{n}<br/></span>
                            ))}
                        </div>
                    </div>
                )}
                <div className="row justify-content-center">
                    <div className="col-xs-12 col-md-4 p-sm bg-white">
                        <Formik
                            initialValues={createInitialValues(query.t)}
                            validationSchema={validationSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validateOnMount={false}
                            onSubmit={handleSubmit}
                            render={({errors}) => (
                                <Form>
                                    {/*We use error.otp because we want to not showe the reason for error */}
                                    <Field
                                        error={errors.otp}
                                        placeholder="New Password"
                                        htmlFor="password"
                                        type="password"
                                    />
                                    <Field
                                        error={errors.otp}
                                        placeholder="Confirm New Password"
                                        htmlFor="password_confirm"
                                        type="password"
                                    />
                                    {/*<Field error={errors.otp} htmlFor="otp" placeholder="Token"/>*/}
                                    <button
                                        className="btn btn-warning block full-width m-b"
                                        type="submit"
                                    >
                                        <i className="fa fa-sign-in"/> Change Password
                                    </button>
                                    <div className="btn-group btn-block">
                                        <a
                                            href="/login"
                                            className="btn btn-sm btn-white "
                                        >
                                            Go back
                                        </a>
                                    </div>
                                </Form>
                            )}
                        />
                    </div>
                </div>
                <div className="row justify-content-center m-t-md">
                    <div className="col-md-4 col-sm-12 border-top">
                        <div className="row m-t-sm">
                            <div className="col text-left">
                                <small>ConDevTec GmbH</small>
                            </div>
                            <div className="col text-center">
                                <small>Made with <i className="fa fa-heart" style={{color: "#d40e0e"}}/> in
                                    Munich</small>
                            </div>
                            <div className="col text-right">
                                <small>&copy; 2018 - {new Date().getFullYear()}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
