import * as React from "react"
import {NavLink} from "react-router-dom";


const Link = (props) => {

    const [active, setActive] = React.useState(false);

    const handleActive = (match) =>{
        if(match){setActive(true)}
        else{
            setActive(false)
        }
        return match
    }

    return (
        <li className={active ? "active" : ""}>
            <NavLink
                isActive={handleActive}
                {...props}
            />
        </li>
    )
};

export default Link;
